import { Injectable } from '@angular/core';
import { ApiService } from 'src/services/api.service';


@Injectable()
export class UsuariosService {
    constructor(private apiService: ApiService) { }

    save(params) {
        return this.apiService.post('/api/admin', params, {});
    }

    list(params) {
        return this.apiService.get('/api/user/pesquisar', params, {});
    }

    listAposta(params) {
        return this.apiService.get('/api/user/apostas', params, {});
    }

    listRifa(params) {
        return this.apiService.get('/api/user/rifas', params, {});
    }

    listPremiosTradicional(params) {
        return this.apiService.get('/api/user/premios-tradicional', params, {});
    }
    listPremiosRifas(params) {
        return this.apiService.get('/api/user/premios-rifas', params, {});
    }

    listRetiradas(params) {
        return this.apiService.get('/api/user/retiradas', params, {});
    }

    listRecarga(params) {
        return this.apiService.get('/api/user/recargas', params, {});
    }
    listSaldoBonus(params) {
        return this.apiService.get('/api/user/saldo-bonus', params, {});
    }
    listExtrato(params) {
        return this.apiService.get('/api/user/extrato-usuario', params, {});
    }
    alterarSenha(params) {
        return this.apiService.patch('/api/user/alterarSenha', params, {});
    }

    show(params) {
        return this.apiService.get('/api/user', params, {});
    }

    acrescentarValorBonus(params) {
        return this.apiService.patch('/api/user/acrescentar-valor-bonus', params, {});
    }

    exportar() {
        return this.apiService.get('/api/user/exportar', {}, {});
    }
    exportarApostas(id) {
        return this.apiService.get(`/api/user/exportar-apostas/${id}`, {}, {});
    }
    exportarRecargas(id) {
        return this.apiService.get(`/api/user/exportar-recargas/${id}`, {}, {});
    }
    exportarRetiradas(id) {
        return this.apiService.get(`/api/user/exportar-retiradas/${id}`, {}, {});
    }
    exportarRifas(id) {
        return this.apiService.get(`/api/user/exportar-rifas/${id}`, {}, {});
    }
    exportarPremiosRifas(id) {
        return this.apiService.get(`/api/user/exportar-premios-rifas/${id}`, {}, {});
    }
    exportarPremiosTradicional(id) {
        return this.apiService.get(`/api/user/exportar-premios-tradicional/${id}`, {}, {});
    }
    exportarExtratos(id) {
        return this.apiService.get(`/api/user/exportar-extratos/${id}`, {}, {});
    }
    procurar(cpf) {
        return this.apiService.get('/api/user/procurar-cpf', {cpf: cpf}, {});
    }
}
