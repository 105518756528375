import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversorDinheiroPipe } from 'src/app/pipes/conversor-dinheiro.pipe';


@NgModule({
  declarations: [ConversorDinheiroPipe],
  imports: [
    CommonModule
  ],
  exports: [
    ConversorDinheiroPipe
  ]
})
export class PipesSistemaModule { }
