import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conversorDinheiro'
})
export class ConversorDinheiroPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    if (value === undefined || value === null) {
      return (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    return (+value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) || 0;
  }

}
