import { Injectable } from '@angular/core';
import { ApiService } from 'src/services/api.service';


@Injectable()
export class CupomService {
  constructor(private apiService: ApiService) {
  }

  save(params) {
    return this.apiService.post('/api/cupom', params, {});
  }

  edit(params) {
    return this.apiService.patch('/api/cupom', params, {});
  }

  retornaLista(params) {
    return this.apiService.get('/api/cupom/list', params, {});
  }

  retornaItem(params) {
    return this.apiService.get('/api/cupom/show', params, {});
  }

  qtdUsuariosPorInfluencer(params) {
    let tipoAcesso = localStorage.getItem("tipoAcesso");
    if ("revenda" === tipoAcesso) {
      return this.apiService.get('/api/cupom/qtdUsuariosPorInfluencer', {}, {});
    } else {
      return this.apiService.get('/api/cupom/qtdUsuariosPorInfluencer', params, {});
    }
  }

  qtdRecargasPorInfluencer(params) {
    let tipoAcesso = localStorage.getItem("tipoAcesso");
    if ("revenda" === tipoAcesso) {
      return this.apiService.get('/api/cupom/qtdRecargasPorInfluencer', {}, {});
    } else {
      return this.apiService.get('/api/cupom/qtdRecargasPorInfluencer', params, {});
    }
  }
}
